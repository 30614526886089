<template>
  <div>
    <vab-query-form style="padding-top: 20px">
      <vab-query-form-left-panel :span="12" style="padding-left: 10px;">
        <el-button @click="goBack">返回</el-button>
      </vab-query-form-left-panel>
      <vab-query-form-right-panel :span="12">
        <el-button :icon="Plus" type="primary" @click="handleEdit($event)">
          绑定新账号
        </el-button>
      </vab-query-form-right-panel>
    </vab-query-form>
    <el-table v-loading="listLoading" border :data="list">
      <el-table-column label="序号" type="index" width="50" />
      <el-table-column label="姓名" prop="name" show-overflow-tooltip />
      <el-table-column label="国际区号" prop="phoneCountryCode" show-overflow-tooltip />
      <el-table-column label="手机号码" prop="phone" show-overflow-tooltip />
      <el-table-column label="操作" show-overflow-tooltip width="420">
        <template #default="{ row }">
          <el-button type="danger" @click="delBind(row, $event)">
            取消绑定
          </el-button>
        </template>
      </el-table-column>
      <template #empty>
        <!-- <el-image
                class="vab-data-empty"
                :src="require('@/assets/empty_images/data_empty.png')"
              /> -->
        <el-empty class="vab-data-empty" description="暂无数据" />
      </template>
    </el-table>

    <edit ref="editRef" :channelId="queryForm.channelId" @fetch-data="fetchData" />
  </div>
</template>

<script>
import {
  defineComponent,
  defineAsyncComponent,
  getCurrentInstance,
  onMounted,
  reactive,
  toRefs,
  onActivated,
} from 'vue'
import { queryDistribution, delChannelDistribution } from '@/api/channel'
export default defineComponent({
  name: 'ChannelManagar',
  components: {
    Edit: defineAsyncComponent(() => import('./components/addContact.vue')),
  },
  setup() {
    const { proxy } = getCurrentInstance()

    const state = reactive({
      queryForm: {
        channelId: '',
      },
      type: '',
      total: 0,
      shopList: [],
      listLoading: false,
      list: [],
      layout: 'prev, pager, next',
      editRef: null,
      upLoadRef: null,
      resultRef: null,
      generateWxRef: null,
      exportRegisterRef: null,
      typeOptions: [
        {
          label: '全部',
          value: '',
        },
        {
          label: '申请会议',
          value: 'HOLD_MEETING',
        },
        {
          label: '申请展览',
          value: 'EXHIBITION',
        },
      ],
    })

    const handleEdit = () => {
      state['editRef'].showEdit()
    }

    const delBind = (row) => {
      if (row.uid) {
        proxy.$baseConfirm('请确认您要删除这个负责人账号的渠道绑定权限', null, async () => {
          const { msg } = await delChannelDistribution(
            state.queryForm.channelId,
            row.uid
          )
          proxy.$baseMessage(`操作成功`, 'success', 'vab-hey-message-success')
          await fetchData()
        })
      }
    }

    const queryData = () => {
      fetchData()
    }

    const fetchData = async () => {
      state.listLoading = true
      const { data } = await queryDistribution(state.queryForm.channelId)
      state.list = data
      state.listLoading = false
    }

    onActivated(() => {
      state.queryForm.channelId = proxy.$route.query.channelId
      queryData()
    })

    const goBack = () => {
      proxy.$router.go(-1)
    }
    return {
      ...toRefs(state),
      handleEdit,
      queryData,
      fetchData,
      delBind,
      goBack,
    }
  },
})
</script>
<style lang="scss" scoped></style>
